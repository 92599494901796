<template>
  <div id="Viplist">
    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary" size="small" icon="el-icon-circle-plus-outline">增加
          </el-button>
          <el-button type="danger" size="small" icon="el-icon-delete">删除</el-button>
        </div>

        <!-- <CommonForm :form="searchForm" :formLabel="dateFormLabel" inline>
        </CommonForm> -->
        <!-- datePicker -->

        <CommonForm :form="searchForm" :formLabel="searchFormLabel" inline>
          <el-button type="primary">搜索</el-button>
        </CommonForm>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData='tableData' :tableLabel="tableLabel" :tableConfig="tableConfig"></CommonTable>
      </template>
    </CommonManageMain>

  </div>
</template>

<script>
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'
export default {
  components: {
    CommonForm, CommonTable, CommonManageMain
  },
  data () {
    return {
      // form
      searchForm: {
        vipname: ''
      },
      searchFormLabel: [

        {
          nodel: 'date',
          label: '选择日期',
          labelOFF: true,
          type: 'datePicker'
        },
        {
          model: 'vipname',
          label: '会员名字',
          labelOFF: true,
          options: []
        }

      ],

      // table 表格 基础数据
      tableData: [
        {
          account: 'axa123123123',
          name: '孙敏',
          phone: 6076073061247682,
          role: 'Lee',
          cDate: '1985-02-10  02:26:15',
          state: false,
          address: '山西省 朔州市 怀仁县'
        },
        {
          account: 'axa123123123',
          name: '孙敏2',
          phone: 60760730612476822,
          role: 'Lee',
          cDate: '1985-02-10  02:26:15',
          state: true,
          address: '山西省 朔州市 怀仁县'
        }
      ],
      tableLabel: [
        {
          prop: 'account',
          label: '账号'
        },
        {
          prop: 'name',
          label: '姓名'
        },
        {
          prop: 'phone',
          label: '电话'
        },
        {
          prop: 'role',
          label: '角色'
        },
        {
          prop: 'cDate',
          label: '创建时间'
        },
        {
          prop: 'state',
          label: '状态'

        },
        {
          prop: 'address',
          label: '地址'
        }
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch
        alterState: 'state',

        page: 1,
        total: 30,
        loading: false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#Viplist {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
}
</style>
